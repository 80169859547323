@import "~@digicert/dcone-common-ui/src/styles/variables";
@import "~@digicert/dcone-common-ui/src/styles/utils/alignment";

// global overrides for ant design components
// React-script V3+ didn't support :local() and :global()

.ant-table-column-sorters {
  display: inline-flex;
}

.ant-table-column-title {
  position: relative;
  padding-right: $spacing-0;
}

.ant-table-filter-icon.ant-dropdown-trigger {
  @include vertical-align;
  right: $spacing-2;
  color: $light-gray;
}

// Dropdown
.ant-btn.ant-dropdown-trigger {
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  color: $dark-gray;

  & .anticon {
    margin-left: 16px;
    line-height: 0;
    font-size: 20px;
    transition: transform $transition-cubic;
  }

  &.ant-dropdown-open {
    & .anticon {
      transform: rotate(180deg);
    }
  }
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  color: $dark-gray;

  & > a {
    color: $dark-gray;
  }
}

.ant-dropdown,
.ant-dropdown-menu,
.ant-dropdown-menu-item.ant-dropdown-menu-item-disabled {
  color: rgba($black, .25);
  & > a {
    color: rgba($black, .25);
  }
}

// Date picker
.ant-picker-date-panel {
  width: auto !important;
}

// Table
.ant-table-pagination {
  justify-content: flex-end;
}

.styled-table {
  & .ant-table.ant-table-small {
    border: none;
    color: $dark-gray;

    & .ant-table-content > .ant-table-body {
      margin: 0;
    }

    & > .ant-table-content .ant-table-row:last-child td {
      border-bottom: 1px solid #e8e8e8;
    }
  }

  & .ant-table-thead > tr > th.tableColumn {
    font-weight: 400;
    color: $blue;

    & .ant-table-filter-icon.ant-table-filter-selected {
      color: $blue;
    }
  }

  & .ant-checkbox-wrapper .ant-checkbox {
    margin-left: 0;
  }

  & .ant-empty.ant-empty-normal {
    margin: 0;
  }
}

// Buttons
.submit-btn {
  &.ant-btn {
    font-size: 14px;
    padding: 0 20px;
    border-radius: $border-radius;
    border-color: $green;
    color: $green;

    &.ant-btn-lg {
      border-radius: $border-radius;
    }

    &:hover {
      border-color: darken($green, 10);
      color: darken($green, 10);
    }

    &:active,
    &:focus {
      border-color: darken($green, 15);
      color: darken($green, 15);
    }

    &.ant-btn[disabled],
    &.ant-btn[disabled]:hover {
      border-color: $green;
      color: $light-gray;
    }
  }
}

.default-btn {
  &.ant-btn {
    font-size: 14px;
    padding: 0 20px;
    border-radius: $border-radius;
    border-color: $light-gray;
    color: $medium-gray;

    &.ant-btn-lg {
      border-radius: $border-radius;
    }

    &:hover {
      border-color: darken($light-gray, 10);
      color: darken($medium-gray, 10);
    }

    &:active,
    &:focus {
      border-color: darken($light-gray, 15);
      color: darken($medium-gray, 15);
    }
  }

  &.ant-btn[disabled],
  &.ant-btn[disabled]:hover {
    border-color: $light-gray;
    color: $light-gray;
  }
}

// Table pagination
.ant-pagination.ant-table-pagination.mini {
  & .ant-pagination-item a {
    color: $medium-gray;

    &:hover {
      color: $blue;
    }
  }

  & .ant-pagination-item-active {
    border-color: transparent;
    background-color: transparent;

    a {
      color: $blue;

      &:hover {
        color: $blue;
      }
    }
  }
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 34px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: auto;
}

// Select
.ant-select-lg {
  height: 34px;
  min-height: 34px;
  display: block;

  &.ant-select-focused {
    border-color: $light-blue !important;
    .ant-select-selection {
      &:active, &:focus, &:focus-visible {
        outline: none;
        border-color: $electric-blue !important;
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0 0 2px 2px rgba($electric-blue, .2);
      }
    }
  }

  & .anticon.anticon-down {
    font-size: 14px;
  }

  & .ant-select-selection--multiple .ant-select-selection__placeholder {
    position: absolute;
    top: 50%;
    right: 9px;
    left: 0;
    max-width: 100%;
    height: 20px;
    overflow: hidden;
    color: #bfbfbf;
    line-height: 20px;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    margin-top: -14px;
  }

  & .ant-select-selection--multiple .ant-select-selection__choice {
    color: $blue;
    position: relative;
    float: left;
    max-width: 99%;
    margin-right: 4px;
    padding: 0 20px 0 8px;
    overflow: hidden;
    background-color: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    cursor: default;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 24px;
    line-height: 24px;
    z-index: 1;
  }

  & .ant-select-selection--multiple .ant-select-selection__rendered {
    height: auto;
    position: relative;
    line-height: 34px;
  }

  & .ant-select-selection--multiple .ant-select-selection__choice__remove {
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 4px;
    font-weight: 700;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    display: inline-block;
    font-size: 11px;
    top: 6px;
  }

  & .ant-select-selection--multiple .ant-select-selection__choice__content {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: margin .3s cubic-bezier(.645,.045,.355,1);
  }

  & .ant-select-selection .ant-select-selection__clear,
  .ant-select-selection__clear,
  & .ant-select-selection:hover .ant-select-selection__clear {
    @include vertical-align;
    opacity: 1;
    right: $spacing-1;
    // antd never assumes that an arrow and clear option would be both available to users
    // on search. So both elements are stacked. The bg, shadow and z-index below hide arrow behind
    background: $white;
    z-index: map-get($z-index, drop-down);
  }

  & .ant-select-clear-icon {
    font-size: 16px;
    color: $medium-gray;
    cursor: pointer;
  }

  .ant-select-search--inline {
    border: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    &::marker {
      display: table;
      content: "";
    }
  }

  .ant-select-search__field__wrap {
    height: 100%;
  }

  .ant-select-search__field {
    background: transparent;
    border: none;
    left: $spacing-1;
    outline: none;
    position: relative;
    top: $spacing-0;
    width: 100%;
  }

  .ant-select-search__field__mirror {
    border: none;
    left: $spacing-1;
    outline: none;
    opacity: 0;
    position: relative;
    top: $spacing-1;
    width: 100%;
  }
}

.ant-select-clear {
  margin-top: #{-$spacing-1};
}

.ant-select-single .ant-select-clear {
  width: 18px !important;
  height: 18px !important;
  right: $spacing-1 !important;
  margin-top: #{-$spacing-1};
}

.ant-select-dropdown {
  padding: 0;
  max-height: 300px;
  overflow: auto;
  .ant-select-dropdown-menu {
    padding: 0;
    list-style: none;
    margin: 0;

    .ant-select-dropdown-menu-item {
      padding: $spacing-1 $spacing-2;
      background: transparent;
      cursor: pointer;
      &:hover {
        background: $light-blue;
      }
    }
  }

  &.ant-select-dropdown--multiple {
    .ant-select-dropdown-menu-item {
      .ant-select-selected-icon {
        display: none;
        color: $blue;
        float: right;
        top: 5px;
        position: relative;
      }
      &.ant-select-dropdown-menu-item-selected {
        .ant-select-selected-icon {
          display: block;
        }
      }
    }
  }
}

.ant-select {
  & .anticon.anticon-down {
    color: $medium-gray;
  }
}

// Collapse from account/tenant/seat details page
.collapse {
  &.ant-collapse.ant-collapse-borderless {
    background-color: transparent;
    color: $dark-gray;

    & .ant-collapse-item {
      border-bottom: 1px solid $light-gray;
      transition: background-color $transition-cubic;

      &:first-child {
        border-top: 1px solid $light-gray;
      }

      &:last-child {
        border-bottom: none;
      }

      & .ant-collapse-header {
        position: relative;
        font-weight: 500;
        color: rgba($dark-gray, 0.5);

        &:hover {
          color: $dark-gray;
        }

        &::before {
          position: absolute;
          top: -1px;
          left: 0;
          width: 30px;
          height: 1px;
          content: '';
          background-color: $blue;
        }

        & i {
          font-size: 18px;
          font-weight: 500;
          color: $dark-gray;
        }
      }

      &.ant-collapse-item-active {
        background: linear-gradient($white, $off-white-2);

        & .ant-collapse-header {
          color: $blue;
        }
      }
    }
  }
}

.collapse-list {
  list-style: none;
  color: $dark-gray;

  & > li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 30px;
    word-break: break-word;

    &::before {
      position: absolute;
      top: 6px;
      left: 15px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: $blue;
      content: '';
    }
  }
}

// Result
.ant-result {
  color: $dark-gray;

  & .ant-result-title {
    color: $medium-gray;
  }

  & .ant-result-subtitle {
    color: $dark-gray;
  }
}

.ant-input {
  color: $dark-gray !important;
}
