@import '~@digicert/dcone-common-ui/src/styles/variables';

.optionalSignInSettings {
  margin: $spacing-9 0 0 0;

  .heading {
    display: inline-block;
  }

  .editIcon {
    background: transparent;
    border: none;
    color: $blue;
    cursor: pointer;
    margin-left: $spacing-1;
    &.disabled {
      color: $light-gray;
      opacity: 0;
      pointer-events: none;
    }
    &:hover {
      color: $dark-blue;
    }
  }

  .description {
    margin: $spacing-1 0;
  }

  .multiSelectContainer {
    margin: $spacing-3 0;

  }

  .actions {
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-column-gap: $spacing-2;
  }
}