@import '~@digicert/dcone-common-ui/src/styles/variables';

$sizes: (
  'xs': $spacing-0,
  's': $spacing-1,
  'm': $spacing-2,
  'l': $spacing-3,
  'xl': $spacing-4,
  'xxl': $spacing-5,
  'xxxl': $spacing-6,
  '4xl': $spacing-7
);

%space {
  width: 100%;
  display: inline-flex;
  align-items: flex-start;
}

.h {
  @extend %space;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
}

.v {
  @extend %space;
  flex-wrap: nowrap;
  align-items: stretch;
  flex-direction: column;
}

@each $size, $spacing in $sizes {
  .h-#{$size}:not(:empty) {
    margin-right: $spacing;
  }

  .v-#{$size}:not(:empty) {
    margin-bottom: $spacing;
  }
}


.inline {
  width: auto;

  & > div {
    width: inherit;
  }
}
