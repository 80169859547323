@import '~@digicert/dcone-common-ui/src/styles/variables';
@import '~@digicert/dcone-common-ui/src/styles/utils/rem-calc';

.card {
  .cantEditLicenseBtn {
    display: none;
  }

  .editLicenseBtn {
    background: transparent;
    border: none;
  }

  .cardHeader {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: $spacing-2;
    margin-bottom: $spacing-2;

    .label {
      font-size: rem-calc(24px);
      font-weight: 300;
    }

    .action {
      svg {
        color: $blue;
        font-size: rem-calc(18px);
        cursor: pointer;
      }
    }
  }

  .cardContent {
    display: grid;
  }

  .cardContentRowHeader {
    background: $off-white-2;
    border-bottom: 1px solid $off-white-3;
    border-top: 1px solid $off-white-3;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: $spacing-2;
    margin: 0 #{-$spacing-3} $spacing-3;
    padding: $spacing-2 $spacing-3;

    p {
      margin: 0;
    }
  }

  .cardContentRow {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-column-gap: $spacing-2;
    margin: 0 #{-$spacing-3};
    padding: 0 $spacing-3 $spacing-3;
    word-break: break-word;

    &:last-of-type {
      padding: 0 $spacing-3 $spacing-1;
    }

    p {
      margin: 0;
      line-height: initial;
      color: $medium-gray;
    }
  }
}