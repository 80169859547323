@import "~@digicert/dcone-common-ui/src/styles/variables";
@import "~@digicert/dcone-common-ui/src/styles/utils/breakpoints";

$oAuthCardWidth: 450px;
$oAuthCardBackgroundColor: $white;
$oAuthCardBorder: 1px solid $light-gray;
$oAuthCardBoxShadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.125);

.menuItem {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  width: 100%;
  text-align: left;
  padding: 5px 12px;
  margin: 0 !important; //override antd negative margin on links
}

.oAuthNotificationIcon {
  color: $blue;
}

.oAuthCardContainer {
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: $spacing-4;
  margin: $spacing-4 0;

  @include breakpoint(large) {
    grid-template-columns: minmax(auto, $oAuthCardWidth) minmax(auto, $oAuthCardWidth);
    grid-column-gap: $spacing-4;
  }

  @include breakpoint(xlarge) {
    grid-template-columns: minmax(auto, $oAuthCardWidth) minmax(auto, $oAuthCardWidth) minmax(auto, $oAuthCardWidth);
  }
}

.oAuthCard {
  background: $white;
  border: $oAuthCardBorder;
  box-shadow: $oAuthCardBoxShadow;
  padding: $spacing-3;
  position: relative;

  .oAuthCardDetails {
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-row-gap: $spacing-4;
    list-style: none;
    margin: 0;
  }

  .oAuthCardOptions {
    border: none;
    background: transparent;
    cursor: pointer;
    position: absolute;
    right: $spacing-3;
  }

  .oAuthCardHeadline {
    font-weight: 300;
    line-height: normal;
    margin-bottom: $spacing-1;
    padding-right: $spacing-1;
    white-space: normal;
    word-break: break-word;
  }

  .oAuthCardLabel {
    color: $medium-gray;
    font-size: 13px;
  }
}