@import "~@digicert/dcone-common-ui/src/styles/variables";

.container {
  border: 1px solid $light-gray;
  box-shadow: 1px 2px 2px $light-gray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 30px 30px 0;
  padding: 24px;
  transition: all $transition-cubic;
  width: 250px;

  &:hover {
    box-shadow: 4px 6px 6px $light-gray;
    border-color: $blue;
    cursor: pointer;
    position: relative;
    top: -1px;
  }
}

.leftContainer {
  display: flex;
  flex-direction: column;
}

.name {
  color: $dark-gray;
  font-size: 16px;
  margin-bottom: $spacing-2;
  padding-right: $spacing-1;
  white-space: normal;
  word-break: break-word;
}

.status {
  color: $medium-gray;
  font-size: 13px;
  margin-bottom: $spacing-1;
}

.tag {
  color: $medium-gray;
  font-size: 13px;
  width: 50px;
}