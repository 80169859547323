@import '~@digicert/dcone-common-ui/src/styles/variables';

.inputWrap {
  margin-bottom: 20px;

  & :global(.ant-calendar-picker) {
    width: 100%;
  }
}

.inputLabel {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  color: $dark-gray;
}

.inlineLabel {
  display: inline-block;
  font-size: 14px;
  width: 20%;
  padding-left: 15px;
}

.inlineInput {
  display: inline-block;
  width: 80%;
}

:global(.ant-input),
:global(.ant-select-selection),
:global(.ant-input-number),
:global(.ant-input-number-input) {
  border-radius: $border-radius !important;
  font-weight: 300 !important;
  color: darken($light-gray, 15%) !important;
  border: 1px solid $light-gray;
  padding: 3.5px $spacing-1;
  position: relative;
  &:hover {
    border-color: #40a9ff;
  }
}

:global(.ant-input-number.ant-input-number-lg) {
  width: 100%;
}

:global(.ant-input-number-handler-wrap) {
  border-radius: 0 $border-radius $border-radius 0;

  & :global(.ant-input-number-handler-up) {
    border-top-right-radius: $border-radius;
  }

  & :global(.ant-input-number-handler-down) {
    border-bottom-right-radius: $border-radius;
  }
}

:global(.ant-select-dropdown-menu-item) {
  color: $dark-gray;

  &:global(.ant-select-dropdown-menu-item-disabled) {
    color: rgba($black, 0.2);
  }
}

.checkboxWrap {
  & :global(.ant-checkbox-wrapper .ant-checkbox) {
    margin-left: 0;
  }
}

.checkboxLabel {
  font-size: 14px;
  margin-left: 10px;
  position: relative;
  
  > div {
    top:-115%;
    left: 115%;
  }
}

.questionMark{
  margin-left: $spacing-1;
}

.switchLabel {
  font-size: 14px;
  margin-right: 10px;
}

.errorLabel,
.inlineErrorLabel {
  font-size: 14px;
  font-weight: 300;
  color: $red;
}

.inlineErrorLabel {
  margin-left: 20%;
}

.selectField {
  width: 100%;

  & :global(.anticon.anticon-down.ant-select-arrow-icon) {
    color: $dark-gray;
    font-size: 14px;
  }
}

// Phone input
.phoneContainer {
  width: 100%;
}

.phoneInput {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 300;
  font-family: inherit;
  font-variant: tabular-nums;
  border-radius: $border-radius;
  border: 1px solid #d9d9d9;
  background-color: $white;
  transition: $transition;
  color: $blue;

  &:hover {
    border-color: #40a9ff;
  }

  &:focus {
    outline: 0;
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  &::-moz-placeholder {
    color: $light-gray;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $light-gray;
  }

  &::-webkit-input-placeholder {
    color: $light-gray;
  }
}

.largePhoneInput {
  height: 40px;
  font-size: 16px;
}

:global .intl-tel-input {
  & .country-list {
    width: 100%;
    box-shadow: none;
  }

  &.allow-dropdown .flag-container .selected-flag {
    outline: 0;
    border: none;
    box-shadow: none;
    border-radius: $border-radius;
    transition: $transition;

    &:hover {
      border: none;
    }

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
}

:global input::-webkit-outer-spin-button,
:global input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:global input[type='number'] {
  -moz-appearance: textfield;
}

// Calendar view
.calendarView {
  position: relative;
  height: 120px;

  & :global(.ant-calendar-picker) {
    position: absolute;
    top: 0;
    left: 0;
  }

  & :global(.ant-calendar-picker-input.ant-input) {
    display: block;
    height: 80px;
    width: 80px;
    border: none;
    background-color: transparent;
    color: transparent !important;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  & :global(.ant-calendar-picker-icon) {
    display: none;
  }
}

.calendar {
  width: 100px;
  text-align: center;
}

.calenderImg {
  height: 80px;
  width: 80px;
  font-size: 30px;
  font-weight: 300;
  font-family: Roboto, sans-serif;
  stroke: $dark-gray;
  fill: $dark-gray;
}

.calenderImgActive {
  stroke: $blue;
  fill: $blue;
}

.calendarDate {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 300;
  color: $blue;
}

.calendarError {
  composes: errorLabel;
  margin-bottom: 0;
}

.required {
  color: $red;
}

.caOrgInfoWrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: $border-radius;
  border: 1px solid $off-white-3;
}

.caOrgInfoBlock {
  flex: 0 0 50%;
}

.inputFlexWrap {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.multiContainer {
  width: 100%;
  margin-bottom: 20px;

  .deleteBtn {
    margin-right: 5px;
    color: $red;

    & + div {
      flex-grow: 1;
    }
  }

  .add {
    margin-bottom: 5px;
  }

  .addBtnIcon {
    margin-right: 3px;
  }

  .fieldContainer {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }
}

.errorLabel {
  margin-bottom: 0;
  font-weight: 300;
  color: $red;
}

.switchWrap {
  display: flex;
}

.leftDirection {
  order: 1;
  margin-left: 20px;
}

.switchNumberField {
  margin-left: 20px !important;
}

.textSwitchSize {
  font-size: 14px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone {
  width: 100% !important;
  height: auto !important;
  color: $blue !important;

  &:focus {
    border-color: #40a9ff !important;
    border-right-width: 1px !important;
    outline: 0 !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
  }

  & + :global(.flag-dropdown),
  & + :global(.flag-dropdown.open) {
    border-radius: $border-radius 0 0 $border-radius !important;

    & :global(.selected-flag) {
      border-radius: $border-radius 0 0 $border-radius !important;
    }
  }

  .instruction {
    font-family: 'Roboto-Regular', 'Roboto', sans-serif;
    font-size: 13px;
    color: $medium-gray;
  }
}

:global(.ant-select-selection--multiple){
  min-height: 34px;
  padding-bottom: 3px;
  cursor: text;
  zoom: 1;

  &:before, &:after {
    display: table;
    content: '';
  }

  :global(.ant-select-selection__choice){
    margin-bottom: 4px;
  }

  :global(.ant-select-selection__rendered){
    ul {
      height: auto;
      overflow: hidden;
      margin: 0;
    }
  }
}