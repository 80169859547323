@import "~@digicert/dcone-common-ui/src/styles/variables";
@import "~@digicert/dcone-common-ui/src/styles/utils/alignment";

.setupUser {
  .contentHeader {
    margin-bottom: $spacing-6;
    text-align: center;
    p {
      color: $medium-gray;
    }
  }

  .nameGroup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $spacing-2;
    margin: $spacing-4 0;
    max-width: 350px;
  }

  .toolTipList {
    margin: 0;
    dt {
      margin-bottom: $spacing-1;
    }
    dd {
      display: list-item;
      list-style-type: disc;
      margin-left: $spacing-2;
    }

    .toolTipNestedItem {
      margin-left: $spacing-4;
      list-style-type: circle;
    }
  }
}