@import "~@digicert/dcone-common-ui/src/styles/variables";
@import "~@digicert/dcone-common-ui/src/styles/utils/rem-calc";

.publicEnrollment {
  height: 100vh;

  .error {
    button {
      margin: $spacing-2 0 0 0;
    }
  }
}