@import '~@digicert/dcone-common-ui/src/styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;

  & :global(.ant-select) {
    margin-left: 0 !important;
    width: 250px;
  }
}

.label {
  margin-bottom: 4px;
}

.hardwareOptionWrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: $spacing-2;
}

%selected {
  cursor: pointer;
  border-color: #40a9ff !important;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.hardwareOption {
  text-align: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
  padding: 10px 8px;
  margin-right: $spacing-2;
  min-height: 95px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    @extend %selected;
  }

  img {
    max-width: 130px;
    margin-bottom: $spacing-1;
  }
}

.selected {
  @extend %selected;
}

.fallbackIcon {
  font-size: 40px;

  & + .fallbackIcon {
    margin-left: $spacing-1;
  }
}