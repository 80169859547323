@import "~@digicert/dcone-common-ui/src/styles/variables";
@import "~@digicert/dcone-common-ui/src/styles/utils/alignment";

.setupLicense {
  .contentHeader {
    margin-bottom: $spacing-6;
    text-align: center;
    p {
      color: $medium-gray;
    }
  }
}