@import "~@digicert/dcone-common-ui/src/styles/variables";

.error,
.anticon.error {
  color: $red;
  padding-right: $spacing-1;
}

.warning,
.anticon.warning {
  color: $yellow;
  padding-right: $spacing-1;
}

.info,
.anticon.info {
  color: $blue;
  padding-right: $spacing-1;
}

.success,
.anticon.success {
  color: $green;
  padding-right: $spacing-1;
}

.default,
.anticon.default {
  color: $off-white-3;
  padding-right: 8px;
}

.initial-spin-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spin-wrap {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow {
  flex-grow: 1
}

.h-100 {
  height: 100%;
}

.table-name {
  color: $blue;

  &:hover {
    text-decoration: underline;
    color: $blue;
  }
}

.caption {
  position: relative;
  margin-bottom: 30px;
  padding-top: 5px;
  font-weight: 400;
  border-top: 1px solid $light-gray;
  color: $dark-gray;

  &::before {
    position: absolute;
    top: -1px;
    left: 0;
    content: '';
    width: 35px;
    height: 1px;
    background-color: $blue;
  }
}

.highlighted {
  padding: 0;
  background-color: $yellow;
}

.w-100 {
  width: 100%;
}

.form-buttons-wrap {
  margin-top: 30px;
  text-align: center;

  & > button,
  & > a {
    margin: 0 10px;
  }
}

.form-title {
  margin-bottom: 30px;
  text-align: center;
  font-size: 40px;
  font-weight: 300;
  color: $blue;
}

.details-label {
  margin-right: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.justify-space-between {
  justify-content: space-between;
}


.justify-space-around {
  justify-content: space-around;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

:-ms-input-placeholder.ant-input { /* IE 10+ */
  line-height: normal;
}

@media all and (-ms-high-contrast:none)
{
  .ant-input { /* IE 10+ */
    line-height: normal;
  }
}

.heartSeat { 
  .caption:nth-child(2) {
    .whiteCircle {
      transition: all .3s ease;
    }
    .progressCircle {
      animation-delay: .2s;
    } 
  }
  .caption:nth-child(3) {
    .whiteCircle {
      transition: all .4s ease;
    }
    .progressCircle {
      animation-delay: .4s;
    }
  } 
  .caption:nth-child(4) {
    .whiteCircle {
      transition: all .5s ease;
    }
    .progressCircle {
      animation-delay: .6s;
    }
  } 

  &:hover .caption:not(:hover){
    circle {
      stroke: $light-gray;
    }
    .whiteCircle {
      box-shadow: 0 10px 10px -5px $light-gray;
      p {
        color: $light-gray;
      }
    }
  }
}


#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  margin-left: -26px;
  text-align: center;
  pointer-events: none;
  
  &:before {
    content: '';
    height: 12px;
    width: 1px;
    top: 0;
    transition: all .3s ease;
    position: absolute;
    border-left: 1px solid;
    z-index: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.title {
  font-size: 10px;
  color: $medium-gray;
  display: block;
  width: 100%;
  margin: 0 auto;
  top: 0;
  position: absolute;
  opacity: 1;
  transform: translateY(-24px);
  transition: all .3s ease-in;
}

.body-wrapper {
  animation: scaleIn .3s ease-in;
  margin-top: 12px;
  border: 1px solid;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: block;
}

.body {
  animation: scaleIn .5s ease-in;
  transition: all .3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background: #fff;
}