@import '~@digicert/dcone-common-ui/src/styles/variables';

.title {
  margin-bottom: $spacing-4;
}

.subTitle {
  margin-bottom: $spacing-3;
}

.selector {
  margin-bottom: 24px;
}

.clientNameLabel {
  font-family: $font-regular;
  font-size: 13px;
  color: $medium-gray;
}

.clientName {
  font-family: $font-regular;
  font-size: 14px;
  margin-bottom: $spacing-3;
  color: $dark-gray;
}

.buttonWrap {
  padding-top: 12px;
}

.cancelButton {
  padding-right: $spacing-2;
}
