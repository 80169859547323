@import "~@digicert/dcone-common-ui/src/styles/variables";
@import "~@digicert/dcone-common-ui/src/styles/utils/rem-calc";

.signInSettings {
  p{
    line-height: rem-calc(24px)
  }

  .label{
    margin-bottom: $spacing-1;
  }

  .status {
    margin: $spacing-3 0 0 0;
    p {
      font-size: 13px;
      color: $medium-gray;
    }
  }
}