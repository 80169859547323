@import '~@digicert/dcone-common-ui/src/styles/variables';
@import '~@digicert/dcone-common-ui/src/styles/utils/rem-calc';

%title1 {
  font-family: $font-light;
  font-weight: $font-weight-light;
  font-size: rem-calc(18px);
}
%title2 {
  font-family: $font-light;
  font-weight: $font-weight-light;
  font-size: rem-calc(16px);
}
%title3 {
  font-family: $font-regular;
  font-weight: $font-weight-regular;
  font-size: rem-calc(14px);
}
%description {
  color: $medium-gray;
  font-size: rem-calc(13px);
}

.disabled {
  opacity: .25;
  pointer-events: none;
}

.features {
  .feature {
    margin-bottom: $spacing-7;
    max-width: map-get($form-element-sizes, xxl);

    &.disabled {
      opacity: .25;
      pointer-events: none;
    }

    .header {
      align-items: center;
      display: inline-grid;
      grid-template-columns: auto auto;

      .appName {
        @extend %title1;
        margin: 0 $spacing-1 0 0;
      }

      .appEdit {
        background: transparent;
        border: none;
        cursor: pointer;
        svg {
          color: $blue;
        }
        &:hover {
          svg {
            color: $dark-blue;
          }
        }
      }
    }

    &:last-of-type {
      margin-bottom: 0;
      .content:last-child {
        border: none;
      }
    }

    .content {
      align-items: start;
      border-bottom: 1px solid $light-gray;
      display: grid;
      grid-template-columns: 1fr 1fr auto;
      grid-template-rows: auto;
      grid-column-gap: $spacing-2;
      grid-template-areas:
      'name name action'
      'description description action'
      'info info action'
      'error error action';
      padding: $spacing-2 0;

      .name {
        @extend %title3;
        grid-area: name;
        margin: 0;
      }

      .description {
        @extend %description;
        grid-area: description;
        margin: 0;
      }

      .additionalInfo {
        color: $medium-gray;
        font-size: rem-calc(13px);
        grid-area: info;
        list-style-position: inside;
        list-style-type: none;
        margin: $spacing-0 0 0 $spacing-2;
        padding: 0;
        li {
          &:before {
            content: '•';
            left: -4px;
            position: relative;
          }
        }
      }

      .error {
        color: $dark-gray;
        grid-area: error;
        margin: 0;
        svg {
          color: $red;
          margin: $spacing-1 $spacing-0 0 0;
        }
      }

      .action {
        grid-area: action;
      }
    }
  }

  .includedFeatures {
    border-bottom: 1px solid $light-gray;
    margin-bottom: $spacing-3;

    .header {
      display: grid;
      grid-template-columns: auto 1fr;
      justify-content: start;
      margin: $spacing-3 0 $spacing-1 0;
      .title {
        @extend %title2;
        margin: 0;
      }
      .tag {
        justify-self: end;
      }
    }

    .includedFeature {
      margin-bottom: $spacing-1;
      .name {
        @extend %title3;
        margin: 0;
      }
      .description {
        @extend %description;
        margin: 0;
      }
    }
  }

  .includedFeatureHeader {
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: start;
    margin-top: $spacing-4;

    .title {
      @extend %title2;
      margin: 0;
      vertical-align: middle;
    }
    .toggle {
      justify-self: end;
    }
    .label {
      margin-right: $spacing-1;
      vertical-align: middle;
    }
  }

  .featureActions {
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-column-gap: $spacing-1;
    margin-top: $spacing-4;
  }
}