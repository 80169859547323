@import "~@digicert/dcone-common-ui/src/styles/variables";
@import "~@digicert/dcone-common-ui/src/styles/utils/alignment";

$form-max-width: 450px;

.bannerContainer{
  margin: $spacing-3 auto;
  max-width: $form-max-width;
  text-align: left;
}

.createAccountMessaging {
  font-family: $font-regular;
  font-weight: $font-weight-regular;
  margin: $spacing-4 auto 0 auto;
  text-align: center;

  a {
    color: $blue;
    &:hover {
      color: $dark-blue;
      text-decoration: underline;
    }
  }
}

.actions {
  button {
    width: 100%;
  }
}

.logInForm {
  &.invalid {
    min-height: 460px;
  }
}

.spinner {
  @include center-align
}

.login {
  width: 100vw;
  height: 100%;
  background: $blue;
  font-family: $font-regular;
  color: $dark-gray;
  text-align: center;
  padding: 82px 0 0 0;
  @media (min-height: 840px) {
    height: 100vh;
  }
}

.dcLogo {
  height: 48px;
  margin: 0 auto 0 auto;
  width: 100%;
  max-width: 172px;
}

.dcOneLogo {
  height: 48px;
  margin: 0 auto 0 auto;
  max-width: 266px;
  width: 100%;
}

.logInForm {
  width: 100%;
  max-width: $form-max-width;
  padding: 0 $spacing-8 $spacing-3 $spacing-8;
  border-radius: 6px;
  background-color: $white;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: $dark-gray;
  text-align: center;
  line-height: normal;
  margin: auto;
  min-height: 320px;
  position: relative;
}

.heading {
  border-radius: 6px;
  font-size: 30px;
  padding-top: $spacing-6;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: $font-light;
  font-weight: 300;
  color: $dark-gray;
  text-align: center;
  line-height: 34px;
}

.checkGroup {
  & span:global(.ant-checkbox) {
    margin-left: 0;
    border: none;
  }
  & span:global(.ant-checkbox-inner) {
    border: 1px solid $blue;
    width: 20px;
    height: 20px;
    border-radius: 4px;

    box-sizing: border-box;
  }
  background-color: $white;
  font-family: 'Arial Regular', 'Arial', sans-serif;
  color: $dark-gray;
  text-align: center;
  line-height: normal;
  margin-top: 80px;
  margin-left: 67px;
  margin-right: 10px;
}

.groupName {
  margin: $spacing-5 auto 0 auto;
  font-size: 14px;
  font-weight: 500;
  font-family: $font-regular;
  text-align: left;
  color: $dark-gray;
  display: block;
}

.agree {
  width: 315px;
  height: 34px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  line-height: normal;
  font-size: 14px;
  display: inline-table;
}

.termsOfUse {
  font-family: $font-regular;
  font-weight: $font-weight-regular;
  margin: 100px auto 0 auto;
  max-width: 640px;
  text-align: center;
  width: 100%;
}

.termsOfUseLinks {
  margin-right: 25px;
  font-size: 11px;
  color: $white;
  &:hover {
    color: $white;
    text-decoration: underline;
  }
  &:last-child {
    margin-right: 0;
  }
}

.copyRights {
  font-size: 11px;
  color: $white;
  margin-top: $spacing-2;
}

.languageOption {
  align-items: center;
  color: $white;
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-column-gap: $spacing-1;
  font-size: 13px;
  margin-bottom: $spacing-1;
}

.languageIcon {
  position: relative;
  top: 2px;
  font-size: 18px;
  margin-right: $spacing-0;
}

.languageOptions {
  background: transparent;
  border: none;
  color: white;
  position: relative;
  top: 1.5px;
}

:global {
  .ant-dropdown-menu {
    padding: 0 !important;
  }
  .ant-dropdown-menu-item {
    padding: $spacing-1 $spacing-2 !important;
  }
  .ant-dropdown-menu-item-active {
    background: $light-blue !important;
  }
  .pub-login {
    background-color: $blue !important;
  }
}

.error {
  margin-bottom: 20px;
  margin-top: -20px;
  padding: 0 !important;

  font-family: $font-regular;
  font-weight: $font-weight-regular;
  color: $dark-gray;
  font-size: rem-calc(14px);

  & .error-icon {
    padding-right: $spacing-1;
    font-size: rem-calc(13px);
    color: $red;
    box-sizing: content-box; // Override antd globals
  }
}

.group {
  margin-bottom: 25px;
}


.invalidUserName {
  border-color: $pale-red;
  background-color: $light-pink;
}

