@import "~@digicert/dcone-common-ui/src/styles/variables";
@import "~@digicert/dcone-common-ui/src/styles/utils/rem-calc";

.container {
  background-color: $white;
}

.editSignInHeader {
  margin: 0 0 $spacing-1 0;
}

.containerEditStandardSignIn {
  background-color: $white;
  padding: $spacing-3 0;

  .formBtnWrap {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: $spacing-2;
    justify-content: start;
  }

  .manageTitleWrap {
    margin-bottom: 0;
  }
}

.section {
  margin-bottom: 20px;
}

.manageBtnWrap {
  text-align: right;
  margin-top: 30px;
}

.alternateActions {
  margin: $spacing-7 0 $spacing-3 0;
}

.actions {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: $spacing-0;
  justify-content: start;
}

.formActions {
  margin-top: $spacing-4;
  button {
    margin-right: $spacing-2;
  }
}

.copyToClipboard {
  margin-left: 15px;
  margin-top: 5px;
  margin-bottom: $spacing-2;
  display: inline-block;
  color: $blue;
}

.copyToClipboard:hover {
  color: $dark-blue;
  cursor: pointer;
}

.browseFileContainer {
  margin: 0 0 10px 0;

  button {
    margin-top: $spacing-2;
    position: relative;
    padding: 0;
    overflow: hidden;
  }
}

.inputFileInput {
  display: none;
}

.addBtnIcon {
  position: relative;
  padding: 5px;
  top: -4px;
  left: -16px;
  color: #76b25c;
  font-size: 18px;
  border: 1px solid #76b25c;
  border-radius: 50%;
}

.inputFileLabel {
  padding: 16px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.fileNameContainer {
  display: inline-block;
  margin: 10px 0 0 12px;
}

.fileNameLabel {
  color: #1373B7;
  margin: 0 8px 0 0;
}

.ssoOptions {
  display: grid;
  grid-row-gap: $spacing-1;
  grid-template-columns: auto;
  margin-bottom: $spacing-4;
}

.ssoOption {
  align-items: center;
  display: grid;
  grid-column-gap: $spacing-1;
  grid-template-columns: auto auto;
  justify-content: start;
  label {
    cursor: pointer;
  }
}

.display {
  display: block;
}

.hide {
  display: none
}

.deleteIcon {
  border: none !important;
  box-shadow: none !important;
  color:red !important;
}

.error{
  color: red;
}

.warningText {
  margin-bottom: 15px;
  padding-top: 5px;
}

.caption {
  h3 {
    display: inline-block;
  }
}

.step {
  font-family: $font-regular;
  height: 30px;
  width: 30px;
  border: 1px solid $blue;
  border-radius: 30px;
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  margin-right: $spacing-2;
  background-color: $white;
}

.sectionBody {
  margin-left: $spacing-6;
}

.authSection {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: rem-calc(8px);
  margin-bottom: rem-calc(26px);

  &.authSectionDisabled {
    cursor: none;
    opacity: .5;
    pointer-events: none;
  }

  .info {
    h4 {
      font-weight: normal;
    }
    p {
      margin-bottom: 0;
    }
  }

  .actions {
    align-items: center;
    label {
      cursor: pointer;
      padding-left: rem-calc(8px);
    }
    input {
      cursor: pointer;
      position: relative;
    }
  }
  
  .header {
    margin: 0 0 $spacing-1 0;
  }
}
