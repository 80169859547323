@import "~@digicert/dcone-common-ui/src/styles/variables";

.secureLayout {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.contentWrap {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: calc(100% - 220px);
  height: 100%;
  background-color: $white;
  overflow: auto;
}

.content {
  flex: 1 1 auto;
  padding: $spacing-3 $spacing-4 0 $spacing-4;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $white;
  margin-top: -27px;
}

:global {
  .resizable-table-body {
    display: grid !important;
    grid-template-areas:
            "header header header"
            "body body body"
            "footer footer footer" !important;

    header {
      grid-area: header;
      left: 0;
      [role='columnheader'] {
        white-space: nowrap;
      }
    }

    section {
      grid-area: body;
      overflow: auto;
      width: max-content !important;
    }

    footer {
      grid-area: footer;
    }
  }
}

// TODO (jp) breadcrumbs should utilize header/breadcrumbs in common ui
// Breadcrumbs
:global(.ant-breadcrumb).breadcrumbWrap {
  margin-left: $spacing-4;
  margin-top: 5px;
  position: relative;
  top: -47px;
  width: 50%;

  & :global(.ant-breadcrumb-separator) {
    margin: 0;
  }

  & :global(.ant-breadcrumb-link) {
    font-size: 13px;
    a {
      color: $blue !important;
      &:hover {
        color: $dark-blue !important;
      }
    }
  }

  & li:last-child {
    a {
      color: $medium-gray !important;
      &:hover {
        color: $medium-gray !important;
      }
    }
  }

  & li:last-child {
    a {
      color: $medium-gray;
    }
  }
}

.customSeparator {
  margin-left: 8px;
  margin-right: 8px;
  color: $medium-gray !important;
}
