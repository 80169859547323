@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  font-style: normal;
  src: url('./fonts/Roboto-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/Roboto-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  src: url('./fonts/Roboto-Medium.woff2') format('woff2');
}