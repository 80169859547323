@import "./fonts";
@import 'global';
@import '../../node_modules/antd/dist/antd.css';
@import '~@digicert/dcone-common-ui/src/styles/variables';
@import '~@digicert/dcone-common-ui/src/styles/typography';
@import '~@digicert/dcone-common-ui/src/styles/utils/font-smoothing';
@import 'overrides';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  line-height: $paragraph-line-height;
}

a {
  color: $blue;
  text-decoration: none;
}

html {
  font-size: 100%;
}

body {
  @include fontSmoothing;
  font-family: 'Roboto', 'Segoe UI', 'Helvetica Neue', sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  background-color: $white !important;
  color: $dark-gray;
  height: auto;
  overflow: auto;
}

#root {
  height: 100%;
}

h1 {
  font-family: $font-regular !important;
  font-size: 24px;
  font-weight: $font-weight-regular !important;
}
