@import "~@digicert/dcone-common-ui/src/styles/variables";
@import "~@digicert/dcone-common-ui/src/styles/utils/rem-calc";

.licenseCardForm {
  color: $dark-gray;

  .licenseCardFormHeader {
    border-top: 1px solid darken($off-white, 10%);
    border-bottom: 1px solid darken($off-white, 10%);
    background-color: $off-white;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: $spacing-2 $spacing-3;
    margin: 0;
    list-style: none;
    strong {
      font-weight: $font-weight-medium;
    }
  }

  .licenseCardFormRowContainer {
    background-color: $white;
    border-bottom: 1px solid darken($off-white, 10%);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: $spacing-2 $spacing-3;
    grid-column-gap: $spacing-3
  }

  .licenseCardFormRow {
    display: grid;
    grid-template-columns: auto;
    &:last-child {
      .licenseCardFormRowItem {
        justify-self: start;
      }
    }
  }

  .errors {
    padding: $spacing-1 0;
    display: none;
    svg {
      color: $red;
    }
  }

  .input {
    font-family: $font-regular;
    font-size: 14px;
    font-weight: 400;
    color: $dark-gray;
    border-radius: 0 $border-radius $border-radius 0;
    border: 1px solid $light-gray;
    outline-style: none;
    text-align: center;
    border-left: none;
    position: relative;
    max-width: 80px;

    &:focus {
      border-color: $blue;
      outline-style: none;
    }

    &.invalid {
      border-color: #ffb5a9;
      background-color: #ffeeec;
    }
  }

  .licenseCardFormRowItemActions {
    display: grid;
    grid-template-columns: 135px auto;
    padding-right: $spacing-3;
    :global {
      .ant-select {
        display: block;
      }
    }
  }

  .licenseCardFormRowItem {
    padding: 0 0 $spacing-2 0;
    &:first-child {
      border-bottom: 1px dashed darken($off-white, 10%);
      height: 90px;
    }
    p {
      margin: 0;
      &.increased {
        color: $green;
      }
      &.decreased {
        color: $red;
      }
    }
    label {
      display: none;
    }
  }
}

.licenseInput {
  font-family: $font-regular;
  font-size: 14px;
  font-weight: 400;
  color: $dark-gray;
  border-radius: 0 $border-radius $border-radius 0;
  border: 1px solid $light-gray;
  height: 13px;
  outline-style: none;
  padding: 16.5px 0;
  text-align: center;
  width: 63px;
  border-left: none;
  position: relative;

  &:focus {
    border-color: $blue;
    outline-style: none;
  }

  &.invalid {
    border-color: #ffb5a9;
    background-color: #ffeeec;
  }
}

.buttonWrap {
  margin: $spacing-3 $spacing-3;
}

.cancelButton {
  margin-right: $spacing-3;
  display: inline-block;
}

.submitButton {
  box-sizing: border-box;
  font-family: 'Roboto-Regular', 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  padding: 11px 15px;
  cursor: pointer;
  color: $white;
  border-color: $dark-blue;
  background-color: $blue;

  &:hover {
    border-color: #016198;
    background-color: #016198;
  }

  &:focus {
    outline: 0;
    border-color: #003756;
    background-color: #004c77;
    box-shadow: 0 0 0 3px #77aacf;
  }
}

.btnDisabled {
  border-color: $off-white-3;
  background-color: $disabled-gray;
  color: $light-gray;

  & button {
    background-color: $disabled-gray;
    border: none;
  }

  &:hover {
    border-color: $off-white-3;
    background-color: $disabled-gray;
    color: $light-gray;
  }
}

.errorMessage {
  padding-left: $spacing-1;
  display: inline;
  color: $dark-gray;
}

.underline {
  padding-bottom: $spacing-3;
  border-bottom: 1px dashed;
}

.actions {
  display: grid;
  grid-template-columns: 130px auto;
}