@import "~@digicert/dcone-common-ui/src/styles/variables";
@import "~@digicert/dcone-common-ui/src/styles/utils/rem-calc";

.secureLayoutWidthAdjustment {
  margin: 0 -24px;

  form {
    padding: 0 20px !important;
    h4 {
      margin-bottom: rem-calc($spacing-2);
    }
  }

  // todo (jp) common ui should have inline group optional prop and styles
  .group {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: rem-calc($spacing-2);
    margin: rem-calc($spacing-2) 0;
  }
}